import React from 'react';
import SEO from "../common/SEO";

import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import ContactOne from "../elements/contact/ContactOne";
import HeaderTwo from '../common/header/HeaderTwo';
const Contact = () => {
    return (
        <>
            <SEO title="Contact | Edgardo Medina" />
            <HeaderTwo/>
                {/*<BreadcrumbOne 
                   
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Contact"
                />*/}
                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Contact Form"
                                      
                                        description = ""
                                    />
                                </div>
                            </div>
                            <ContactOne />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
               
        </>
    )
}
export default Contact;